.dropbtn {
    width: inherit;
    background-color: rgba(0,0,0,0);
    color: #4D4F5C;
    padding: 5px;
    margin: 0 0 0 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    border: none;
    text-align: left;
}

.dropdown {
    width: 130px;
    position: relative;
    display: inline-block;
    border: 1px solid #D7DAE2;
    border-radius: 4px;
    box-shadow: 0px 2px 3px #0000000D;
}

.dropbtn:hover, .dropdown:hover, .dropdown:focus, .dropdown a:hover {
    cursor: pointer;
    background-color: rgb(238, 238, 238);
}

.dropDownContent {
    display: none;
    position: absolute;
    width: inherit;
    max-height: 150px;
    margin-top: 1px;
    background-color: rgb(250, 250, 250);
    overflow-y: scroll;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
    z-index: 1;
}

.dropDownContent a {
    color: #768191;
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.show {
    display: block;
}

.arrow {
    display: inline-block;
    position: relative;
    right: 0;
    width: 0; 
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #A4AFB7;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 0;
}