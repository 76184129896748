/* CSS para CARD default */

.Card {
    flex: 1;
    padding: .75rem;
    margin: .75rem;
    min-width: 17rem;
    max-width: 17rem;
    border-radius: .25rem;
    background: white;
    border: 0 #e8ecf1 solid;
    border-width: 0 2px 2px 0;
}

.Card p {
    margin: 0;
    font-size: .85rem;
}

.Card .CardHeader {
    display: flex;
    align-items: center;
    margin: .5rem 0;
}

.Card .CardHeader .ImageCropper,
.Card .CardHeader p {
    display: inline-block;
}

.Card .CardHeader .ImageCropper {
    background: #dccaf8;
    position: relative;
    border-radius: 50%;
    margin-left: 10px;
    width: 1.5rem;
    height: 1.5rem;
}

.Card .CardHeader .ImageCropper img {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Card .Itens p {
    font-weight: 700;
    padding: .5rem 0;
    border: 0 #e8ecf1 solid;
    border-width: 0 0 1px 0;
}

.Card .Itens p:last-child {
    border: none;
}

.Card .Itens p div {
    display: inline-block;
    background: black;
    height: .7rem;
    width: .7rem;
    border-radius: 50%;
    margin-right: .4rem;
}

.Card .Itens p div.Green {
    background: green
}

.Card .Itens p div.Red {
    background: red
}

/* CSS para CARD INFO */

.Card.Info .Number {
    font-size: 4rem;
    font-weight: 500;
}

.Card.Info .Number span {
    color: black;
    font-size: 2rem;
    font-weight: 400;
    font-style: none;
}

.Card.Info span {
    font-size: .7rem;
    font-weight: 500;
    cursor: pointer;
    color: rgb(83, 173, 234)
}

/* CSS para CARD FILLER */

.Card.Filler .Circles {
    width: 100%;
    height: calc(7rem - 24px);
    position: relative;
    overflow: hidden;
}

.Card.Filler .Cropper {
    height: 115px;
    overflow: hidden;
    position: relative;
}

.Card.Filler .Cropper .Unit {
    position: absolute;
    font-size: .5rem;
}

.Card.Filler .Cropper .Unit:nth-child(1) {
    top: 90%;
    left: 0;
}

.Card.Filler .Cropper .Unit:nth-child(2) {
    top: 50%;
    left: 6%;
}

.Card.Filler .Cropper .Unit:nth-child(3) {
    top: 23%;
    left: 20%;
}

.Card.Filler .Cropper .Unit:nth-child(4) {
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
}

.Card.Filler .Cropper .Unit:nth-child(5) {
    top: 23%;
    left: 72%;
}

.Card.Filler .Cropper .Unit:nth-child(6) {
    top: 50%;
    left: 83%;
}

.Card.Filler .Cropper .Unit:nth-child(7) {
    top: 90%;
    left: 90%;
}

.Card.Filler .Cropper .Value {
    top: calc(90% - 1.5rem);
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    font-size: 1.5rem;
}

.Card.Filler .Cropper .Value i {
    font-size: 1.1rem;
    font-style: none;
}

.Card.Filler .Cropper .Value b {
    font-size: 1.1rem;
    font-weight: 500;
}

.Card.Filler .Cropper svg  {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.Card.Filler .Cropper svg circle {
    fill: transparent;
    stroke: #e1e1e1;
    stroke-width: .75;
    z-index: 1;
    stroke-dasharray: 11 11;
    stroke-dashoffset: 11;
}

.Card.Filler .Cropper svg circle:nth-child(2) {
    stroke:#1e8bc3;
    z-index: 2;
    stroke-dasharray: 11 11;
    stroke-dashoffset: -7;
}

.Card.Filler .Cropper svg circle:nth-child(3) {
    stroke: #823bf6;
    z-index: 2;
    stroke-dasharray: 11 11;
    stroke-dashoffset: -4;
}

.Card.Filler .Legends {
    margin-top: 1rem;
}

.Card.Filler .Legends .Blue {
    background: #1e8bc3;
    width: .65rem;
    height: .65rem;
    display: inline-block;
    margin: 0 5px;
}

.Card.Filler .Legends .Purple {
    background: #823bf6;
    width: .65rem;
    height: .65rem;
    display: inline-block;
    margin: 0 5px 0 0;
}

.Card.Filler .Legends p {
    font-size: .65rem;
    display: inline-block;
}

.Card.Filler svg .Pointer {
    transform-origin: 50% 50%;
    transition: all 1s ease-out;
}