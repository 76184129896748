.container {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 50px;
    top: 50px;
    width: calc(100vw - 50px);
    height: calc(100vh - 50px);
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
    align-items: center;
}

.itemsCard {
    height: 215px;
    margin: 30px 30px 15px 30px;
}

.beaconCard {
    height: 215px;
    margin: 30px 30px 15px 30px;
}

.graphCard1 {
    height: 215px;
    margin: 30px 30px 15px 30px;
}

@media only screen and (max-width: 576px) {
    .container {
        width: 100vw;
        left: 0;
    }
}