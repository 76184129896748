.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: inherit;
    height: inherit;
    padding: 0 20px 20px 20px;
    background-color: white;
    font-family: "Roboto", sans-serif;
    box-shadow: 0px 2px 2px #0000003D;
}

.row0 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 65px;
}

.dots {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bigDot {
    width: 12px;
    height: 12px;
    border: 2px solid #2699FB;
    border-radius: 50%;
    background-color: white;
}

.smallDot {
    width: 4px;
    height: 4px;
    background-color: #BCE0FD;
    border-radius: 50%;
}

hr {
    margin: -6px 0 0 0;
    padding: 0;
    background-color: #BCE0FD;
}

.numbers {
    display: flex;
    justify-content: space-between;
}

.number {
    font-size: 1rem;
}

.legends {
    display: flex;
    justify-content: space-between;
}

.legend {
    font-size: 0.6rem;
    text-align: center;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
}