.dropdown {
    position: relative;
    display: inline-block;
    width: inherit;
    height: inherit;
}

.dropbtn {
    width: inherit;
    background-color: rgba(0,0,0,0);
    color: #768191;
    padding: 5px;
    margin: 0 0 0 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    border: none;
    cursor: pointer;
    text-align: left;
}

.dropbtn:hover, .dropbtn:focus {
    background-color: rgb(238, 238, 238);
}

.dropDownContent {
    display: none;
    position: absolute;
    left: 5px;
    background-color: rgb(250, 250, 250);
    overflow: auto;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
    z-index: 1;
}

.dropDownContent a {
    color: #768191;
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown a:hover {
    background-color: rgb(238, 238, 238);
}

.show {
    display: block;
}

.arrow {
    display: inline-block;
    position: relative;
    right: 0;
    width: 0; 
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #7C8796;
  }