.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: inherit;
    height: inherit;
    padding: 20px 20px 30px 20px;
    background-color: white;
    font-family: "Roboto", sans-serif;
    box-shadow: 0px 2px 2px #0000003D;
    overflow-y: hidden;
    overflow-x: auto;
}

.row0 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.graph {
    margin-top: -40px;
    width: 100%;
    height: 100%;
}

.grid {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
    padding-left: 15px;
}

.gridLine {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.gridLine div {
    color: #43425D;
    font-size: 0.6rem;
    margin-bottom: -5px;
    margin-left: -15px;
}

.xAxis {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0 0 0 15px;
    color: #43425D;
    font-size: 0.6rem;
    transform: translateY(-100%);
}

.barsArea {
    height: 100%;
}

.bars
{
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.bars div {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.legends {
    display: flex;
    align-items: center;
}

.legends div {
    display: flex;
    align-items: center;
}

.firstLegend {
    margin-right: 30px;
}

.legend {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid black;
    margin-right: 5px;
}